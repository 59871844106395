<template>
  <div :class="[
      'banner',
      type
    ]">
    <div :style="{'padding-top': `${options.padding}`, 'padding-bottom': `${options.padding}` }">
      <div class="d-flex">
        <div class="banner__bar"></div>
        <div class="banner__title">
          <span style="font-family: 'Roboto Bold', sans-serif;">{{ boldTitle }}</span>{{ title }}
        </div>
      </div>
      <div class="banner__description">
        {{ description }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Banner',
  props: {
    boldTitle: {
      default: null,
      type: String
    },
    title: {
      default: null,
      type: String
    },
    description: {
      default: null,
      type: String
    },
    type: {
      default: null,
      type: String
    },
    options: {
      default: () => {
        return {
          padding: '50px'
        }
      },
      type: Object
    }
  }
}
</script>

<style lang="scss">
.banner {
  color: white;
  font-family: 'Roboto Light', sans-serif;
  background-color: #222c33;

  &--admin {
    background-image: url("../../assets/images/admin-banner.jpg");
    background-size: cover;
  }

  &__bar {
    background: #ffc200;
    height: 30px;
    width: 150px;
    margin-right: 25px;
  }

  &__title {
    font-size: 30px;
    line-height: 32px;
  }

  &__description {
    margin-left: 175px;
    line-height: 30px;
  }
}
</style>
