import moment from 'moment';

const dateMixin = {
    methods: {
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY hh:mm:ss')
        }
    },
}
export default dateMixin


